<template>
    <div class="modal-mask" :class="{ 'modal--scrollable': scrollable }">
        <div class="modal-wrapper">
            <div class="modal-container" :class="classes">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        fullScreen: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            return {
                'modal--full-screen': this.fullScreen,
                'modal--scrollable': this.scrollable
            }
        }
    },
    beforeCreate() {
        document.body.classList.add('no-scroll')
    },
    // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
    destroyed() {
        document.body.classList.remove('no-scroll')
    }
}
</script>

<style lang="scss" scoped>
body.no-scroll {
    overflow: hidden;
}

.modal--full-screen {
    width: 100vw;
    height: 100vh;
}
.modal--scrollable {
    overflow: auto;
}
.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100001;
    background-color: rgba(0, 0, 0, 0.5);
    // transition: all 3s ease-in-out;
    display: grid;
}

.modal-wrapper {
    display: grid;
    place-items: center;
}

.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s ease;
}
.slide-fade-enter {
    opacity: 0;
    transform: translateX(10px);
}

.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
// .modal-enter-to {
// opacity: 0;
// transform: scale(1);
// }

// .modal-leave-active {
//     opacity: 0;
//     transform: scale(0.5);
// }

// .modal-enter-active {
//     opacity: 1;
//     transform: scale(1);
// }
// .modal-enter .modal-container,
// .modal-leave-active .modal-container {

// }
</style>
