<template>
    <textarea
        class="c-textarea text-primary"
        v-bind="$attrs"
        :class="classes"
        @input="$emit('input', $event.target.value)"
    ></textarea>
</template>

<script>
export default {
    name: 'Textarea',
    emits: ['input'],
    computed: {
        classes() {
            return {
                [`c-textarea--${this.size}`]: true,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.c-textarea {
    resize: none;
    min-height: 170px;
    border: 0;
    outline: 0;
    border-radius: 5px;
    text-align: center;
    font-family: inherit;
    font-weight: 500;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    width: 100%;

    &::placeholder {
        color: $primary;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 17px;
    }

    &:focus {
        outline: none;
        // border: 1px solid $primary;
        box-shadow: 0 0 1px 1px $primary;
    }
}
</style>
