<template>
    <VueAutosuggest
        v-model="searchText"
        :suggestions="suggestions"
        :input-props="{
            class: 'gb-input gb-input--text-center text-primary',
            autocomplete: 'none', // chrome issue - should be off
            ...$attrs
        }"
        :render-suggestion="renderSuggestion"
        :get-suggestion-value="getSuggestion"
        ref="autosuggest"
        @blur="onBlured"
        @keydown.tab.prevent="tabHandler"
        @selected="onSelected"
        @input="selected = null"
    >
    </VueAutosuggest>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import logger from '@/utils/logger.js'
export default {
    name: 'AutosuggestQuery',
    components: {
        VueAutosuggest
    },
    apollo: {
        searchQuery: {
            query() {
                return this.gqlQuery.query
            },
            variables() {
                return {
                    q: this.searchText
                }
            },
            update(data) {
                logger.log('this.gqlQuery.name', this.gqlQuery.name, data)
                this.suggestions = [{ data: [...data[this.gqlQuery.name]] }]
                if (data[this.gqlQuery.name] && data[this.gqlQuery.name][0]) {
                    this.fallback = data[this.gqlQuery.name][0]
                }
                return data[this.gqlQuery.name]
            },
            debounce: 250,
            error(error) {
                logger.log('Error fetching search results:', error)
            },
            skip() {
                return !this.searchText.length
            }
        }
    },
    props: {
        gqlQuery: {
            type: Object,
            required: true,
            default: () => ({})
        },
        renderSuggestion: {
            type: Function,
            default: (suggestion) => suggestion.item
        },
        getSuggestion: {
            type: Function,
            default: (suggestion) => suggestion.item
        },
        initialVal: {
            type: String,
            default: ''
        }
    },
    emits: ['input'],
    data() {
        return {
            searchText: this.initialVal,
            suggestions: [],
            debounceMillilseconds: 250,
            timeout: null,
            selected: null,
            fallback: null
        }
    },
    watch: {
        selected() {
            this.$emit('input', this.selected)
        }
    },
    methods: {
        onSelected(event) {
            this.selected = event.item
        },
        onBlured() {
            if (!this.selected && this.fallback) {
                // TMP: use fallback
                const fallback = { ...this.fallback }
                this.searchText = fallback.name
                this.onSelected({ item: fallback })
                this.fallback = null
                // this.suggestions = [];
            }
            if (!this.selected && !this.fallback) {
                // show user error - missing/invalid location
            }
        },
        tabHandler() {
            // preventing tab out - handle tab?
        }
    }
}
</script>

<style lang="scss">
@import '@/sass/variables.scss';

.gb-input {
    padding: 1rem 1rem;
    font-size: 1.125rem;
    line-height: 1.25rem;
    border: 0;
    outline: 0;
    border-radius: 0.25rem;
    font-family: inherit;
    font-weight: 500;
    width: 100%;

    &:active {
        box-shadow: 0 0 1px 2px $primary;
    }

    &:focus {
        box-shadow: 0 0 1px 1px $primary;
    }

    &::placeholder {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 17px;
        color: $primary;
    }

    // &:invalid {
    //     box-shadow: 0 0 1px 1px red;
    //     color: red;
    // }

    &:disabled {
        background: transparent;
        box-shadow: 0 0 1px 1px $primary;
    }

    &[type='tel'] {
        letter-spacing: 1.8px;
    }
}

.gb-input--text-left {
    text-align: left;
}

.gb-input--text-center {
    text-align: center;
}

.gb-input--round {
    border-radius: 3rem;
}

#autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.autosuggest__results-container {
    position: relative;
    width: 100%;
}

.autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0.5rem 1rem;
    overflow: scroll;
    max-height: 200px;
}

.autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    display: grid;
    row-gap: 0.5rem;
}

.autosuggest__results .autosuggest__results_item {
    cursor: pointer;
    padding: 1rem;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
}

.autosuggest__results .autosuggest__results_title {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results .autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #ddd;
}
</style>
