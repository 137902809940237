<template>
    <div class="grid">
        <clipper-fixed
            ref="clipper"
            :src="src"
            class="clipper"
            :round="round"
            :area="90"
            :grid="false"
            :ratio="aspectRatio"
        >
            <template #placeholder class="placeholder"> No Image </template>
        </clipper-fixed>
        <Button label="Clip" primary square @click="confirm" />
        <Button label="Cancel" beige square @click="$emit('cancel')" />
    </div>
</template>

<script>
import Button from '@/components/Button'
export default {
    components: {
        Button
    },
    props: {
        src: {
            type: String,
            required: true
        },
        aspectRatio: {
            type: Number,
            default: 1
        },
        round: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input', 'cancel'],
    methods: {
        confirm() {
            const canvas = this.$refs.clipper.clip()
            this.$emit('input', canvas)
            this.$emit('cancel')
            // this.$refs.clipper.clip().toBlob(blob => {
            //     const file = new File([blob], 'thefilename', {
            //         type: blob.type
            //     })

            //     this.$emit('input', file)
            //     this.$emit('cancel')
            // })
        }
    }
}
</script>

<style lang="scss" scoped>
.grid {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
}

.clipper {
    width: 300px;
    height: 350px;
}
// .clipper__container {

//     .clipper {
//         width: 300px;
//         height: 300px;
//     }
//     button {
//         margin-top: 10px;
//     }
//     button + button {
//         margin-left: 10px;
//     }
// }
</style>
