<template>
    <div class="image-uploader">
        <clipper-upload class="gb-image--fit-container" @input="handleUpload($event)">
            <slot v-if="!temp" name="temp" />
            <img v-else :src="temp.toDataURL()" class="gb-image--fit-container" />
        </clipper-upload>
        <Modal v-if="modal">
            <ImageClipper
                v-model="temp"
                :src="url"
                :round="profile"
                :aspect-ratio="profile ? 1 : 3 / 2"
                @cancel="modal = false"
                @input="handleInput($event)"
            />
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/modals/Modal'
import ImageClipper from '@/components/ImageClipper'
export default {
    name: 'ImageUploader',
    components: {
        Modal,
        ImageClipper
    },
    inject: ['getMyCurrentProfile'],
    props: {
        profile: {
            type: Boolean,
            default: false
        },
        cover: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    data() {
        return {
            url: '',
            temp: null,
            modal: false
        }
    },
    methods: {
        toFile(image) {
            return new Promise((resolve) => {
                image.toBlob((blob) => {
                    resolve(
                        new File([blob], 'test', {
                            type: blob.type
                        })
                    )
                })
            })
        },
        handleUpload(event) {
            this.url = event
            this.modal = true
        },
        async handleInput(event) {
            const file = await this.toFile(event)
            this.$emit('input', file)
        }
    }
}
</script>

<style lang="scss" scoped>
.image-uploader {
    height: 100%;
}
</style>
